.subreddit-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 2em;
    gap: 1em;
}

.subreddits {
    display: flex;
    overflow-x: scroll;
    border: 1px solid var(--main-font);
    border-radius: 7px;
    padding: 10px;
    gap: 5px;
}

@media only screen and (min-width: 750px) {
    .subreddit-list {
      width: 30%;
    }
    .subreddits {
        flex-direction: column;
        border: none;
    }
}