.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2em;
    align-items: center;
    gap: 10px;
}


.logo-container {
    display: flex;
    align-items: center;
}
.logo-container:hover {
    cursor: pointer;
}

.reddit-logo {
    color: var(--main-dark-blue);
    margin-right: 2em;
}