.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  color: var(--main-font);
}

.App li {
  list-style: none;
}

.body {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: 2em;
  gap: 20px;
}

.global-box-shadow {
  box-shadow: 0 10px 20px #d7d7d7;
}

@media only screen and (min-width: 750px) {
  .body {
    flex-direction: row;
  }
  .posts-list {
    width: 70%;
  }
}