:root {
  --main-grey: #d7d7d7;
  --main-dark-blue: #5296dd;
  --main-light-blue: #92bddf;
  --main-white: #ffffff;
  --main-orange: #ff6314;
  --main-font: #444;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  width: 100vw;
  height: fit-content;
  overflow-x: hidden;
}