.posts-list {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media only screen and (min-width: 750px) {
    .posts-list {
      width: 70%;
    }
  }