.post {
    width: 100%;
    display: flex;
    padding: 1em;
    padding-left: 0;
    min-height: 200px;
    height: fit-content;
    overflow: hidden;
}

.post.pending {
    align-items: flex-end;
    animation: pending 1s linear infinite alternate;
    animation-play-state: running;
    
}

@keyframes pending {
    0% {
        background-color: white;
        /* background: linear-gradient( 135deg,
            var(--main-white) 0%, 
            var(--main-grey) 0%, 
            var(--main-white) 10%
            ); */
    }
    100% {
        background-color: var(--main-grey);
        /* background: linear-gradient( 135deg,
            var(--main-white) 100%, 
            var(--main-grey) 110%
            /* var(--main-white) 80% 
            ); */
    }
}

.vote-count {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 10%;
    height: 100%;
    max-height: 300px;
    align-items: center;
    justify-content: center;
}

.vote-count p {
    font-weight: bold;
}

.vote-arrow:hover {
    cursor: pointer;
}

.vote-arrow.up.false {
    color: green;
}

.vote-arrow.down.false {
    color: red;
}

.post-main-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    padding: 1rem;
    gap: 1rem;
}

.post-body h3:hover {
    cursor: pointer;
}

.post-body {
    width: 90%;
    margin: 1em 0;
}

.post img {
    width: 100%;
}

.post-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid var(--main-grey);
    padding: 5px 0;
}

.post-comments-list {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.username {
    color: var(--main-dark-blue);
    font-weight: bolder;
}

.comments-tile {
    display: flex;
    align-items: center;
    gap: 2px;
}

.comments-tile:hover {
    cursor: pointer;
}